/* Input Group */
.dropdown-list-editable > .input-group {
    margin-left: -10px;
}

/* Form Control  */
.dropdown-list-editable select {
    border: solid 1px #e9e9e9;
    outline: none!important;
    font-size: 16px;
    line-height: 30px;
    color: inherit;
    height: 40px;
    overflow-y: hidden!important;
    /* "Hack" to remove expand-arrow in select box */
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* Form Control; Mode: Normal */
.dropdown-list-editable .form-control.normal {
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    transition: none;
}

.dropdown-list-editable .input-group {
    background-color: #fff;
}

/* Form Control; Mode: Normal */
.dropdown-list-editable .input-group:hover .form-control.normal {
    border-color: #ccc;
    background-color: #fff;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-left-style: dashed;
    border-right-style: solid;
    border-top-style: dashed;
    border-bottom-style: dashed;
}

/* "Hack" to remove expand-arrow in select box */
.dropdown-list-editable select::-ms-expand {
    display: none;
}

/* Input Group - Addons */
.dropdown-list-editable .input-group .input-group-addon {
    opacity: 0;
    border-style: none;
}

.dropdown-list-editable .form-control.editing ~ .input-group-addon {
    display: none;
}

.dropdown-list-editable .input-group:hover .input-group-addon {
    opacity: 1;
    border-style: dashed;
    cursor:pointer;
}

/* Input Group - Buttons */
.dropdown-list-editable .form-control.normal ~ .input-group-btn {
    display: none;
}

.dropdown-list-editable .input-group-btn .btn {
    background-color: white;
    height: 40px;
}

.dropdown-list-editable .button-save {
    color: #7ed321;
}

.dropdown-list-editable .button-cancel {
    color: #d0021b;
}