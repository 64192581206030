.globalRessursbruk{
    margin-top:10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.globalRessursbruk label{
    font-size: 16px;
    font-weight: bold;
}

.globalRessursbruk > .kontrakt-velger{
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


.globalRessursbruk > .kontrakt-velger > .form-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.ressursbruk-global-innmelding-container > h2 {
    margin-left: 60px;
}

.globalRessursbruk #GlobalRessursbrukOkButton{
    width:69px;
    height: 50px;
}

.filterdRessursbruk {
    margin: 20px 10px 0 0;
    padding: 40px 50px 0 40px;
}

.filterdRessursbruk h3 {
    margin-left: 15px;
}

.ressursbruk-filter-container {
    display: flex;
    flex-direction: row;
    
}

.ressursbruk-filter-container > .ressursbruk-filter-element {
    margin: 10px 15px;
}

.filter-prosesskoder-search {
    font-weight: normal;
    width: 200px;
}

/* 
} */
.filterdRessursbruk #filterLabel{
    font-size: 21px;
    font-weight: bold;
    margin-right: 10px;
}

.filterdRessursbruk .form-group{
    /* margin-left:10px; */
    width: 140px;
}

.filterdRessursbruk #filterdatepickerFrom_group>input{
    height: 40px;
    width: 30px;
}

.globalRessursbruk .table-responsive{
   width: 100%
}
