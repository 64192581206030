.messages-search-container {
    /* background-color: rgb(233, 233, 233); */
    /* clear: both;
    display: inline;
    /* position: fixed; */
    /* width: 100%; */
    /* z-index: 10;  */
}

.melding-page-header-search {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* max-height: 76px; */
}

.melding-page-header-search h1 {
    padding: 5px 10px;
}

.melding-page-header-search .melding-page-heading {
    min-width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.melding-page-header-search-search {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}

.messages-search-search {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: 18px 51px 19px 0px;
}

.messages-search-search .tips-block .btn.btn-link {
    font-weight: normal;
}

.messages-search-container-visible {
}
.messages-search-container-hidden {
}

.messages-search-search-element {
    height: 34px;
    margin-bottom: 0;
}

.messages-search-search-element input[type='text'] {
    width: 35vw;
    max-width: 35vw;
    margin-bottom: 0;
}

.messages-search-search-element .help-block {
    margin-top: 0;
}

.messages-soketips-button {
    position: relative;
    top: -10px;
    padding-top: 0;
}

.messages-search-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
}

div.soketips-dialog > .modal-dialog {
    min-width: 75vw;
}
