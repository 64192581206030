.editable-ressursbruk {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.editable-ressursbruk>.btn-default {
    margin: 0;
    padding: 0 7px;
    border-radius: 0;
    background-color: #fff;
}


.editable-ressursbruk>.btn-default>.button-save {
    color: #7ed321;
    margin-bottom: 10px; 
}
.editable-ressursbruk>.btn-default>.button-cancel {
    color: #d0021b;
    margin-bottom: 10px; 
}

.editable-ressursbruk-activate-button {
    background-color: transparent;
    border-color: transparent;
    padding-right: 25px; 
}

.editable-ressursbruk-activate-button > .no-comment-value {
    font-style: italic;
}

.editable-ressursbruk-activate-button.btn-default[disabled],
.editable-ressursbruk-activate-button.btn[disabled] {
    /* margin: 0; */
    /* padding: 0 7px; */
    border-radius: 0;
    background-color: transparent;
    opacity: 1;
}


.editable-ressursbruk-activate-button:hover, 
.editable-ressursbruk-activate-button:focus {
    border-color: #ccc;
    background-color: #fff;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-left-style: dashed;
    border-right-style: solid;
    border-top-style: dashed;
    border-bottom-style: dashed;
    padding-right: 0;
    /* white-space: pre-wrap;
    word-wrap: break-word; */
}
.editable-ressursbruk-activate-button:hover::after, 
.editable-ressursbruk-activate-button:focus::after {
    content: "";
    display: inline-block;
    background: url('../../images/pennIcon.png');
    background-repeat: no-repeat; 
    background-size: 16px 16px;
    height: 20px;
    width: 20px;
    margin-left: 5px;
    vertical-align: middle;
}

.editable-ressursbruk-number {
    
}
.editable-ressursbruk-date {
    
}
.editable-ressursbruk-time {
    
}
.editable-ressursbruk-comment {
    padding: 10px 0;
    align-items: flex-start;
    display: flex;
    /* flex-direction: column; */
    width: 90%;
    flex-wrap: wrap;
}
.editable-ressursbruk-comment-form {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: stretch;
}
.editable-ressursbruk-comment> span {
    margin-left: 12px;
    width: 100%
}
.editable-ressursbruk-comment> button {
    text-align: left;
    margin-left: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding-right: 0;
}
.editable-ressursbruk-comment-form > textarea {
    font-weight: normal;
    overflow: auto;
}
.editable-ressursbruk-activate-button-comment {
    /* width: 500px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    margin-left: 0;
    padding-right: 0;
}