/* Index Css */

body {
  background-color: white;
  font-family: Lato, serif;
  padding-top: 65px;
  color: #4d4d4d;
  overflow: auto;
  min-height: -webkit-fill-available;
}

/* Fix for image / close button being blocked by tabs in safari  */
#lightboxBackdrop img {
   max-height: calc(100vh - 15vh) !important;
}


.container {
  width: 100%;
  padding: 0px;
}
.mainContainer {
  min-height: 600px;
}
.panel {
  border: none;
  box-shadow: none;
}
.Login_center_div,
.Login_center_div > .panel {
  min-height: 600px;
  margin-top: -6px;
}
#LoginDiv .form-group {
  margin-bottom: 0px;
}
input[type='text'],
input[type='password'] {
  border-radius: 7px;
  margin-bottom: 10px;
  max-width: 480px;
}
input[type='checkbox'] {
  border-radius: 7px;
  width: 17px;
  height: 17px;
  border: 2px #4d4d4d solid;
}
.userAdminstrationHeader {
  height: 70px;
  font-size: 40px;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  text-align: center;
  color: #4d4c4d;
  padding-top: 10px;
  padding-bottom: 10px;
}

.knapp-new {
  padding: 10px 16px 3px 16px;
  font-size: 16px;
  color: black;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  cursor: pointer;
}

.knapp-new:hover {
  text-decoration: none;
  color: black;
}

.knapp-new > span {
  color: #ffa500;
}

#Dropdownlist {
  height: 40px;
}
#DropdownlistLogin {
  height: 40px;
  width: 480px;
}
table > tbody > tr > td.text-muted {
  background-color: #5bc0de;
}

.detail-header {
  background-color: rgba(242, 248, 252, 0.5);
  border: 1px solid #f1f1f1;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}

span.glyphicon-plus {
  font-size: 16px;
  color: #ffa500;
  top: 1px;
}

button.bym-add-btn {
  border-radius: 6px;
  background: #f3f3f3 none;
  border: solid 1px #e9e9e9;
  color: #4d4c4d;
  font-size: 16px;
  font-weight: bold;
  outline: 0 none;
  float: right;
  margin-left: 20px;
  box-shadow: none;
  padding-right: 20px;
  text-align: center;
}

.glyphicon {
  margin-right: 10px;
  margin-top: 5px;
  font-size: 20px;
}

.filters {
  margin-top: 21px;
  margin-bottom: 17px;
}

.filters .active {
  color: #4a4a4a;
}

.filters .btn {
  font-size: 13px;
  font-weight: normal;
  padding: 0 8px;
}
.hrWhite {
  margin: 10px;
  border: white solid 2px;
}
.filters button ~ button,
.filters button ~ button:hover,
.filters button ~ button:active,
.filters button ~ button:focus {
  border-left: 1px solid #979797;
}
label {
  font-weight: normal;
  font-size: 12px;
}
#label-16px {
  font-weight: normal;
  font-size: 16px;
}
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.disable-text-input {
  background-color: lightgray;
}
#disable-text-input {
  background-color: lightgray;
}

.container-padding {
  padding-left: 51px;
  padding-right: 51px;
}

.color-orange {
  color: orange;
}

#bg-lightgray {
  background-color: rgba(155, 154, 155, 0.2);
}

#bg-navbar {
  background-color: #e7e7e7;
}
#custom-link {
  text-decoration: none;
  color: #000000;
}
#valueFront {
  color: #4d4d4d;
  text-decoration: none;
  cursor: auto;
}
.caret {
  border-top: 7px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}
.glyphicon .glyphicon-user {
  margin-left: 10px;
}
