.filterRemoveAll {
    border: none;
    background-color: white;
    text-align: center;
    line-height: 30px;
    border: 1px solid black;
    margin-right: 5px;
    margin-left: auto;
    display: flex;
    align-items: center;
}
.filterRemoveAll span {
    padding: 0 9px;
}

.filterRemoveAll button {
    display: flex;
    align-items: center;
}

.filterRemoveAll button:last-child {
    margin-left: auto;
    display: flex;
    align-items: center
}

.filterRemoveButtons div {
    display: flex;
}
