.add-processcode{
    margin-top:50px;
    padding-left: 10px;
}
.add-processcode input, .add-processcode select, .add-processcode button{
    height:40px;
}
.prosesskode-table > .prosesskode-table-tbody > tr:hover {
  color: #4d4d4d;
  background-color: #cde3f2 !important;
  border-color: #cee4f2;
}