.datepicker-css-override {
    border-radius: 4px 0px 0px 4px!important;
    /*margin-bottom:10px;*/
    max-width: none!important;
}

.table-datePicker-override table > thead {
    background-color: initial;
    border: initial;
}

.table-datePicker-override table > thead > tr,
.table-datePicker-override table > tbody > tr {
    height: initial;
}

.table-datePicker-override table > tbody > tr > td {
    border: initial;
    padding: 5px;
    vertical-align: initial;
}

.table-datePicker-override table > tbody > tr > td:first-child {
    padding-left: 5px;
}