.list-sort-btn.btn-default:active {

    color: #4d4d4d;
    background-color: transparent;
    border-color: transparent;  
}
.list-sort-btn.btn-default>.list-sort-btn-text {
    /* margin-left: 15px;   */
}
.list-sort-btn.btn-default {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;  
    color: #4d4d4d;
    background-color: transparent;
    border-color: transparent;  
}
.list-sort-btn-active.btn-default {
    text-decoration: underline;
    font-weight: bold; 
}

.list-sort-btn-icon {
    transition: transform 450ms ease-in-out;
}

.list-sort-btn-active > .list-sort-btn-icon-asc {
    transform: rotate(180deg);
}
.list-sort-btn-icon-desc {
    transform: rotate(0deg);
}
