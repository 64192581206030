
.multi-filter-dropdown {
    display: block;
    height: 55px;
}
.multi-filter-dropdown .glyphicon-search{
    float:right;
    margin-top:-45px;
    right:10px;
    color:rgb(216 ,216, 216);    
}
.multi-filter-dropdown  .glyphicon-remove-sign{
    float:right;
    z-index: 2;
    margin-top:-5px;
    width:10px;
}
.multi-filter-dropdown #searchCleanBtn{
    float:right;
    margin-top:-40px;
    right:10px;
    width:20px;
    height:20px;
    z-index: 1;
    margin-right:12px;
    border-style: none;
    background-color: white;

}

input::-ms-clear {
    display: none;
}
.multi-filter-dropdown > button {
    height: 55px;
    margin: 0px;
    border: none;
    border-radius: 0px;
    background-color: rgb(255, 255, 255);
}

.multi-filter-dropdown > button:hover {
    height: 55px;
}

.multi-filter-dropdown-list {
    max-height: 350px;
    overflow-y: auto;
    z-index: 2000;
    padding:5px;
    min-width:308px;
}
.multi-filter-dropdown-list #selectedItemBgColor{
    /*background-color: #f1f1f1;*/
    margin-top:2px;
}
ul.multi-filter-dropdown-list >li>div{
   color: rgb(74, 74, 74);
}
ul.multi-filter-dropdown-list >li>div>label>span, ul.multi-filter-dropdown-list li>ul>li>div>label>span{
    font-size:16px;
    margin-left:5px;
    margin-top:-5px;
    vertical-align: text-bottom;
    letter-spacing: 0.3px;
    cursor: pointer;
}

/*ul.multi-filter-dropdown-list >li>label>div:hover{
    background-color:#f3f3f3;
    cursor: pointer;
}*/
ul.multi-filter-dropdown-list >li:hover{
    background-color:#f3f3f3;
    cursor: pointer;
    display:block;
}

ul.multi-filter-dropdown-list >li>div:hover{
    cursor: pointer    
}
/*.multi-filter-dropdown-list .btnClose{
    margin-top:10px;    
    margin-left:2px;
    padding:5px;
}*/
.multi-filter-dropdown-list .btnClose{
    margin:10px 20px 15px 15px;
    padding:5px;
    width:220px;
    height:40px;
}
ul#meldingstyperMultiFilters {
    min-width:500px;
}