/* NewUserForm Css*/

.newUserForm input,
.newUserForm label,
.newUserForm textarea,
.newUserForm select {
    max-width: 375px;
}

.newUserForm input,.newUserForm .Select, .newUserForm #formControlsSelect {
    width: 350px;
}
 #formControlsSelect{
     width: 250px;
     margin-top:-10;
 }
.newUserButtons .error p {
    background-color: #ffd9d9;
    color: #a94442;
    text-align: center;
    display: block;
    width: 100%;
    padding: 15px;
}
