.meldingerAndkartContainer {
    position: relative;
    margin-top: 0px;
}

#meldingerTable table{
    margin-top: 0px;
}

#leafletDiv {
    position: fixed;
    top: 301px; /*245px;*/
    right: 0px;
    bottom: 0px;
    width: 50%;
    /* height: 90%; */
    z-index: 0;
}
#leafletDiv.search-active {
    top: 346px;
}
.leaflet-container {
    height: 100%;
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif !important; /* Må overstyre en defaultverdi i leaflet dist */
}

.leaflet-container {
    margin-top: 10px;
    position: relative;
}

.meldingDetailleafletDiv .leaflet-container {
    height: 600px;
}
.marker-cluster-custom {
    background: rgb(77, 77, 77);
    border: 6px solid rgb(163, 163, 163);
    border-radius: 50%;
    color: rgb(255, 255, 255);
    height: 40px;
    line-height: 29px;
    text-align: center;
    width: 40px;
}

.meldingDetailleafletDiv input[name='addressSearch'] {
    margin-bottom: -50x;
    width: 350px;
}
#hidepanelbtn {
    width: 20px;
    height: 40px;
    padding: 1px;
}
.col-xs-12 {
    padding: 0;
}
.col-sm-6 {
    padding: 0;
}

.customPopup {
    padding-top: 10px;
    min-width: 200px;
}
/*#statusButton{
  border:1px solid gray;
  border-radius: 10px;
  padding:3px 15px 3px 15px;
  color:rgb( 74, 74, 74);
  text-decoration: none;
}*/

#popupRightPil {
    margin-top: -5px;
}
#closeMoveMarkeren {
    margin-left: 20px;
    font-size: 14px;
}
#moveMarkeren {
    margin-left: 5px;
    font-size: 14px;
    width: 40px;
    padding: 5px;
}
#materialIcon {
    vertical-align: bottom;
    padding-top: 5px;
}
.leaflet-top .leaflet-control {
    margin-top: 5px;
}

.leaflet-control-zoom {
    margin-top: 8px;
}

.kartlagTooltip .tooltip.right .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #000000; /* black */
    border-width: 0 5px 5px;
}
.tooltip.left .tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #000;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

#kartLayerBox {
    overflow-y: scroll;
    max-height: 150px;
}

.filterButton {
    background-color: transparent;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.filterButtonWithBorder {
    width: 244px;
    text-align: left;
    background-color: #f2f8fc;
    border-radius: 10px;
    padding-top: 0px;
    border: 1px gray solid;
    max-height: 51rem;
    overflow-y: auto;
    height: auto;
}

@media (max-height: 700px) {
    .filterButtonWithBorder {
        max-height: 27rem;
    }
}

@media (min-height: 701px) and (max-height: 900px) {
    .filterButtonWithBorder {
        max-height: 38rem;
    }
}

#withouttopborder {
    width: 183px;
    background-color: #f2f8fc;
    margin-top: -10px;
    padding: 10px;
    border: 1px gray solid;
    border-top: none;
}
#withtopborder {
    width: 183px;
    background-color: #f2f8fc;
    padding: 10px;
    border: 1px gray solid;
}

@media (max-width: 767px) {
    .meldingDetailleafletDiv #filterButtonWithBorder,
    .meldingDetailleafletDiv #filterButtonWithoutBorder {
        margin-top: 50px;
        margin-right: 0px;
    }
    #leafletDiv {
        display: none;
    }
    .newMeldingForm .meldingDetailleafletDiv #filterButtonWithoutBorder,
    .newMeldingForm .meldingDetailleafletDiv #filterButtonWithoutBorder {
        margin-top: 0px;
        margin-right: 0px;
    }
}

.leaflet-control-layers-overlays,
.leaflet-control-layers-separator {
    display: none;
}

.leaflet-control > .leaflet-control-layers-toggle {
    position: absolute;
    right: 90000px;
}
