/*Page Header Css*/

.page-header {
    background-color: rgb(233, 233, 233);
    margin-top: -1px;
    height: 76px;
    border-bottom: 1px solid #979797;
    margin-bottom:0px;
    width: 100%;
    z-index: 10;
    display: flex;
    padding-bottom: 0;
}

.page-header-fixed {
    position: fixed;
}

.page-header-placeholder {
    height: 75px;
    background-color: rgb(233, 233, 233);
}

.page-header h1 {
    padding: 0;
    margin: 0;
    color: #4d4c4d;
    font-size: 32px;
    font-weight: bold;
}


@media (max-width:900px) {
    .page-header h1{
	font-size: 16px;
	font-weight: bold;
	vertical-align: middle;
	margin-left: 11px;
}
.page-header{
    width: 100%;
    background-color: rgb(233, 233, 233);
}
}

.page-header .glyphicon {
    padding-left: 22px;
    padding-right: 22px;
    margin: 0;
}

#user-profile {
    /*padding-top: 10px;*/
}