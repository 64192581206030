.alert,
.spinner-div {
  position: fixed;
  z-index: 2000000;
  text-align: center;
  width: 100%;
  height: 70px;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 10;
}

.alert {
  position: fixed;
  top: 70px;
  min-height: 70px;
  height: auto;
}

.spinner-div {
  position: fixed;
  top: 200px;
}

.spinner-circle-div {
  position: fixed;
  z-index: 1000;
  text-align: center;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 150px;
  bottom: 10;
}
