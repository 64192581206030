.bymelding-navbar-brand > span {
	overflow: hidden;
	padding-right: 0;
	max-width: 15%;
	text-overflow: ellipsis;
}

.navbar .divider-vertical {
	height: 59px;
	/*margin: 0 9px;*/
	/*border-right: 1px solid #ffffff;*/
	border-left: 1px solid #f2f2f2;
	margin:0px;
}

.navbar .drift-link > form > input[type="submit"] {
	text-transform: uppercase;
	vertical-align: middle;
	height: inherit;
    color: #4d4c4d;
	padding: 20px;
	font-weight: normal;
}

.navbar .drift-link > form > input[type="submit"] {
	text-transform: uppercase;
	vertical-align: middle;
	height: inherit;
}

.navbar-inverse .divider-vertical {
	/*border-right-color: #222222;*/
	border-left-color: #111111;
}

.header-text {
	font-size: 24px;
	font-weight: bold;
	vertical-align: middle;
	margin-left: 11px;
}

/* header-text-longname
header-text-shortname */
/* .navbar-right{margin-right:10px;} */
.header-text-shortname {
	display: none;
}

@media (max-width:1400px) and (min-width:1100px) {
    .header-text-longname {
        display:none;
	}
	.header-text-shortname {
		display: inline;
	}
}​

.navbar-right li{
	text-transform: uppercase;
	vertical-align: middle;
	height: inherit;
}

.navbar-right li.divider-vertical {
	border-color: #4d4c4d;
	height: 61px;
}

.navbar-default .navbar-nav > li > a {
	color: #4d4c4d;
}

.nav > li > a {
	padding: 20px;
}

/*.nav > li.dropdown > a {
	padding-right: 0px;
}*/

.navbar-header {
	padding-bottom: 5px;
}

.navbar {
	margin-bottom: 0;
	border-bottom: 3px solid #F6A623;
	height: 64px;
}

.navbar-default {
	background: rgb(216, 216, 216) none;
	color: #4d4c4d;
}

.navbar-default .navbar-brand {
	color: #4d4c4d;
}

.navbar-brand {
	margin:0;
	padding-top:9px;
}

.navbar-brand > img {
	display: inline-block;
}

#userprofile{
	height: 30px;
	padding-right: 25px;
	width: 100%;
	margin-top:-10px;
	white-space: nowrap;
	text-transform: none;
}
.userprofile-navitem > a >div>span  {
	padding-top:-9px;	
}
#userprofile >span#userglyph{
	float:left;
	width:33px;
}

#userprofile >span#changeRole > .btn-link{
	color:rgb(0, 113, 187);
	text-decoration: none;
	line-height: 1em
}
#userprofile>div#profile-dropdown{
	padding: 3px;
	height: 40px;
	margin-top:2px;
	background-color: #fff;	
	text-align: right;
	cursor:default;
}

#userprofile>div#profile-dropdown > .btn-link{
	color:rgb(0, 113, 187);
	text-decoration: none;	
	text-align:right;
}
	

#collapsibleRole:hover{
	background-color: #f1f1f1;
}
#collapsibleRole>button{
	display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
	white-space: nowrap;
	text-decoration: none;
}
#collapsibleRole>div>button{
	padding: 3px 20px;
	margin-left:10px;
	text-decoration: none;
	
}
@media (max-width:900px) {
    .header-text {
	font-size: 16px;
	font-weight: bold;
	vertical-align: middle;
	margin-left: 11px;
}
}

@media (max-width: 1200px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
		background:#d8d8d8;
		
    }
    .navbar-toggle {
        display: block;
		background:#d8d8d8;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
		background:#d8d8d8;

    }
    .navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
  		display:block !important;
	}
}
 .navbar-default .navbar-nav > li > a:focus {
	 font-weight: bold;
 }
.navbar-default .navbar-nav > .active > a {
	 font-weight: bold;
 }
