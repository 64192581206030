

/* Table styling */
#ressursbruktable > tbody > tr,
#ressursbruktable > tfoot > tr {
    height: 50px;
}

#ressursbruktable .control-label {
    font-size: 16px;
    font-weight: bold;
}

#ressursbruktable th .form-control {
    font-size: 16px;
    font-weight: normal;
    height: 40px;
}

#ressursbruktable .form-group,
#ressursbruktable .form-control {
    margin-bottom: 0px;
}

#ressursbruktable > thead > tr > th {
    /* padding-top: 0px; */
    padding-right: 6px;
    padding-left: 6px;
    /* padding-bottom: 22px; */
}

/* Undo default table row styles */
#ressursbruktable > thead,
#ressursbruktable > thead > tr > th,
#ressursbruktable > tbody > tr:nth-child(2n) > td, 
#ressursbruktable > tbody > tr:nth-child(2n) > th {
    background-color: transparent;
    border: none;
}

/* Table row styling */
#ressursbruktable thead > tr > th:first-child,
#ressursbruktable tbody > tr > td:first-child {
    padding-left: 15px;
}

#ressursbruktable > tbody > tr:nth-child(4n+1) {
    background-color: rgb(241, 241, 241);
}

#ressursbruktable > tbody > tr:nth-child(4n+2) {
    /* background-color: rgba(241, 241, 241, 0.4); */
}

/* Kommanter row styling */
#ressursbruktable > tbody > tr.ressursbruk-comment {
    height: auto;
}

/* "Pris" column styling */
.ressursbruk-td-pris {
    text-align: right;
}

.sumRow {
    background-color: #f3f3f3;
    text-align: right;
    font-weight: bold;
    vertical-align: middle!important;
}

.ressursbruk-td-pris > span {
    min-width: 120px;
    display: inline-block;
}

/* Kommentar styling */
#ressursbruk-row-kommentar {
    width: 50%;
    height: auto;
    min-width: 300px;
    margin: 15px 10px 35px 25px!important;
}

/* Tjeneste styling */
#tjenestetable > .glyphicon {
    top:-0.2em;
}

#styleglyphicon {
    padding-left:0em;
    padding-right:0em;
}


.ressursbruk-innmelding-form-container {
    margin: 20px 10px;
    display: flex; 
    flex-direction: column; 
    background-color: #f1f7fb;
    padding: 40px 50px;
}

#ressursbruk-dato{
    padding-top:80px;
}

#ressursbruk-time{
    padding-top:80px;
}

.kategori-velger-first {
    margin-bottom: 10px;
}
.kategori-velger {
    width: 500px;
}
.ressursbruk-innmelding-form {
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
    flex-wrap: nowrap;
}

.ressursbruk-innmelding-form-kommentar {
    display: flex; 
    flex-direction: row; 
    justify-content: flex-start;
    flex-wrap: nowrap;
    word-break: break-word;
    width: 100%;
    font-weight: normal;
}

.ressursbruk-innmelding-form-section-mengde {
    padding-top:80px;
    display: flex; 
    flex-direction: row; 
    justify-content: flex-end;
    align-items: top; 
}
.ressursbruk-innmelding-form-section-mengde > #ressursbruk-enhet {
    margin: 35px 0 0 10px;
}

.ressursbruk-innmelding-form-section-tid > label > div, 
.ressursbruk-innmelding-form-section-dato > label > div  {
    margin-top: 5px; 
    font-weight: normal;
}

#ressursbruk-kommentar > .form-group {
    width: 500px;
}

.ressursbruk-innmelding-form-kommentar > #ressursbruk-kommentar >.form-group > label {
    display: flex; 
    flex-direction: column; 
    margin-left: 0;
}

.ressursbruk-innmelding-form-kommentar > #ressursbruk-kommentar >.form-group > label > textarea {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
   border-radius: 4px;
}

.ressursbruk-innmelding-form-submit {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-right: 10px;
}

.ressursbruk-innmelding-form > .ressursbruk-innmelding-form-section {
    margin: 0 10px 0 0;
}
.ressursbruk-innmelding-form > .ressursbruk-innmelding-form-section:last-child {
    margin: 0;
}
.ressursbruk-innmelding-form > #ressursbruk-tjeneste {
    /* width: 500px; */
}


.ressursbruk-innmelding-form > .ressursbruk-innmelding-form-section > .ressursbruk-innmelding-form-kommentar {
    width: 817px;
    height: 129px;
}

.ressursbruk-innmelding-form-container > h2 {
    margin-bottom: 20px;
    margin-top: 15px;
}

.ressursbruk-innmelding-form-container .form-group > .control-label {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
}
.ressursbruk-innmelding-form-container #timepicker input {
    height: 34px !important;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    border: 1px solid #ccc;
}

.ressursbruk-filter-datepicker-input-group {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.ressursbruk-filter-datepicker-input-group > button {
    /* padding-top: 5px; */
    width: 32px;
    height: 34px;
}
.ressursbruk-filter-datepicker-input-group > button:disabled > span {
    opacity: 0.5;
}
.ressursbruk-filter-element > label > .ressursbruk-filter-datepicker-input-group {
    margin-top: 5px;
}

#ressursFilterFraDato_group > .no-border-right,
#ressursFilterTilDato_group > .no-border-right {
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.ressursbruk-filter-datepicker-input-group:focus-within,
#ressursFilterFraDato_group:focus-within,
#ressursFilterTilDato_group:focus-within {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
    border-radius: 5px;
}

.ressursbruk-filter-datepicker-input-group> input[type="text"]:focus,
#ressursFilterFraDato_group > input[type="text"]:focus,
#ressursFilterTilDato_group > input[type="text"]:focus {
    border-color: rgb(204, 204, 204);
    box-shadow: none;
    outline: none;
}