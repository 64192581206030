.geonorge-sokeresultat-container {
  position: absolute;
  list-style: none;
  padding: 0;
  z-index: 900;
  width: 480px;
  max-height: 400px;
  overflow-y: scroll;
  border: 1px solid #979797;
}
.geonorge-sokeresultat-container.hidden {
  display: none;
}

.geonorge-sokeresultat-container > li {
  display: block;
  width: 100%;
  color: #4d4d4d;
  border: none;
  background-color: white;
}
.geonorge-sokeresultat-container > li {
  display: block;
  width: 100%;
  padding: 12px 20px;
  font-size: 15px;
  text-align: left;
  border: none;
  cursor: pointer;
}
.geonorge-sokeresultat-container > li.inactive {
  color: #4d4d4d;
  background-color: white;
}

.geonorge-sokeresultat-container > li:hover,
.geonorge-sokeresultat-container > li.active {
  background-color: #01436a !important;
  color: #fff !important;
}

.combobox-wrapper > .input-group {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  height: 40px;
}
.combobox-wrapper > .input-group > input {
  height: 40px;
}
.combobox-wrapper > .input-group > button {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 4px 6px;
  border: 1px;
  border-color: #ccc;
  border-style: solid;
  border-radius: 7px;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.combobox-wrapper > .input-group > button > .glyphicon {
  top: 2px;
}
.combobox-wrapper > .adressesok-label {
  font-size: 16px;
  font-weight: bold;
}
