/* Contract Css */

#AddNewContract #Dropdownlist, #AddNewContract input{
    max-width: 400px;
}

#close-button {
    line-height: 12px;
    width: 18px;
    font-size: 10pt;
    font-family: tahoma;
    margin-top: 2px;
    margin-right: 2px;
    position:absolute;
    top:0;
    right:0;

    padding: 0;
    border: none;
    background: none;
}

#close-button:hover {
    color: red;
}

#add-process-btn {
    width: 130px;
}

#gyldigfra, #gyldigtil{
    padding-right: 0;
}

#formControlsPris {
    width: 90px;
}

.editableKontraktNavn >#editablewidth >.editable-field >.editable-field-textarea{
   font-size : 12px;
   padding-left:0px;
}

#editableToDate >.editable-field:hover>.editable-field:hover .form-control{
    font-size : 12px;
    padding-left:0px;
}

.editableDate >#dateRange >span > div {
 
    top:-24px;
    margin-left:65px;
    height:30px;
    width:200px;
}
.editableDate >#dateRange >span > div >.editable-field >.editable-field-textarea {
    font-size : 12px;
    padding-left:1px;
    height:30px;
    width:200px;
}
.editableDate >#dateRange >span > div >.editable-field >.input-group-addon{
     height:20px;
     top:0px;
     padding:0px;
}
.editableDate >#dateRange >span > div >.editable-field >.input-group-addon >.glyphicon  {
    
    height:20px;
    font-size:20px;
    padding:0px;
    margin-top:-5px;

    }
