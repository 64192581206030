
.rapportForm #Dropdownlist{
    width: 133px;
}
.rapportForm #entrepreneur, .rapportForm #avtaler, .rapportForm #kontrakter{
    width: 220px;
}

 .btn-default{
    margin:0px 10px 0px 10px;
    font-size: 16px;
    font-weight: bold;
}

#rapporterTable  #rapport-modal-button{
    border-radius: 6px;
    width:220px;
    height:40px;
}
#rapporterTable > tbody > tr:nth-child(2n) > td, #rapporterTable > tbody > tr:nth-child(2n) > th {
    background-color:rgba(241, 241, 241, 0.74);
}
