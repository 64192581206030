.varsel-wrapper {
    display: flex;
    flex-direction: column;
}

.varsel-container {
    display: flex;
    flex-grow: 1;
}

.varsel-container .varsel {
    display: flex;
    width: 100%;
    max-width: 934px;
    padding: 10px 24px;
}

.varsel-container .varsel.advarsel {
    background-color: #ffe7bc;
    border-left: 2px solid #f9c66b;
}
.varsel-container .varsel.informasjon {
    background-color: #d1f9ff;
    border-left: 2px solid #6fe9ff;
}
.varsel-container .varsel.feil {
    background-color: #ffdfdc;
    border-left: 2px solid #ff8274;
}
.varsel-container .varsel.feil-rettet {
    background-color: #c7fde9;
    border-left: 2px solid #43f8b6;
}

.varsel-container .varsel .flex {
    display: flex;
    gap: 16px;
    overflow-y: auto;
    align-items: center;
}

.varsel-container .varsel p {
    color: #2a2859;
    font-family: Oslo Sans, Lato, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px; /* 155.556% */
    letter-spacing: -0.2px;
    margin: 0;
}

/* Support for ipads in landscape for max 1190px */
@media (max-width: 1190px) {
    .varsel-container .varsel {
        padding: 5px;
    }
    .varsel-container .varsel .flex {
        gap: 5px;
    }
    .varsel-container .varsel .flex p {
        font-size: 12px;
        line-height: 16px;
    }
}
