.grunnkart-velger-container {
  margin-top: 5px !important;
  display: flex;
  flex-direction: row;
}

.grunnkart-velger {
  display: flex;
  flex-direction: column;
}

.grunnkart-velger > .btn {
  margin: 0;
  padding: 2px;
  font-size: small;
}

.grunnkart-velger > .btn {
  margin: 0;
  padding: 2px;
  font-size: small;
}

.grunnkart-velger-container > .grunnkart-options {
  width: 45px;
  height: 45px;
  margin: 0;
  padding: 0;
  border: 2px #fff solid; 
  background-size: 45px 45px;
}

.grunnkart-velger-container > .grunnkart-option-kart { 
  background-image: url('../../images/map-preview-detail.jpg');
}

.grunnkart-velger-container > .grunnkart-option-satelitt { 
  background-image: url('../../images/map-preview-sattelite.jpg');
  color: #fff;
}