.ressursbruk-td-mengde > div {
    width: 150px;
}

.ressursbruk-td-mengde
    > div
    > .editable-field-jira
    > .editable-field-jira-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.ressursbruk-header-row {
    background-color: #f3f3f3;
}

.ressursbruk-td-tjeneste-beskrivelse {
    width: 550px;
}

.ressursbruk-tr > td {
    background-color: inherit;
}

/* Undo default table row styles */
#ressursbruktable > thead,
#ressursbruktable > tbody > tr:nth-child(4n + 1),
#ressursbruktable > thead > .ressursbruk-header-row > th {
    background-color: transparent;
    border: none;
    border-left: solid 0.5px #ccc;
}

#ressursbruktable > thead > .ressursbruk-header-row > th:focus-within {
    background-color: #e6e6e6;
}

#ressursbruktable > tbody > .ressursbruk-tr.editable-enabled {
    background-color: #f1f7fb;
}

#ressursbruktable > tbody > .ressursbruk-tr.editable-disabled {
    background-color: #fff;
}

.ressursbruk-header-pagination-row {
    background-color: rgb(221, 221, 221);
    height: 20px !important;
}
.ressursbruk-header-pagination-row > th {
    padding: 0;
}
.ressursbruk-header-pagination-cell {
    text-align: center;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ressursbruk-header-pagination-cell-btn {
    border: none;
    padding: 4px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.ressursbruk-header-pagination-cell-btn>.glyphicon {
    margin-top: 0;
    font-size: 16px;
}
