
/*#ImageContainer{
    border: 2px dashed gray;
    min-height: 180px;
    width: 100%;
    padding:5px;
}*/
.hideImages{
    display: none
}
.imageDiv{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}


#imageUploaderThumbnails{
    width:180px;
    height: 180px;
    border:1px gray solid;
    margin:0px;
}

#imageUploaderThumbnailsRemove{
     position: relative;
     top:-72px;
     left: -27px;
     cursor: pointer
}

.imageUploaderThumbnails {
    height: 150px;
    border:1px #000 solid;
    margin: 5px 5px;
}

.imageUploaderThumbnailsRemove{
    position: relative;
    left: -29px;
    cursor: pointer;
}

.imageUploaderThumbnailsDocument {
    margin: 5px;
    padding: 5px;
    height: 100px;
    width: 150px;
    border:1px #000 solid;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}

.imageUploaderThumbnailsDocumentIcon.glyphicon {
    font-size: 50px;
}

.imageUploaderThumbnailsDocumentFileName {
    width: 100%;
    word-wrap: break-word;
    text-align: center;
}

#dokumentasjon-utfoert-arbeid-dokumenter {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
.utfoert-arbeid-dokument {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #f1f1f1;
    margin: 0 4px 4px 0;
    padding: 5px;
}
.utfoert-arbeid-dokumenter-dokumentinfo {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100px;

}

.utfoert-arbeid-dokumenter-dokumentnavn {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.utfoert-arbeid-dokumenter-download{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}
.utfoert-arbeid-dokumenter-download.glyphicon {
    margin: 0;
    font-size: 30px;
}
.utfoert-arbeid-dokumenter-dokumentikon.glyphicon {
    font-size: 45px;
}


/* #dokumentasjon-utfoert-arbeid-dokumenter>li>a {
    color: inherit;
    padding: 5px;
} */

.bildeopplasting-modal-img-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* padding: 10px; */
}

.dokumentasjon-utfoert-arbeid>div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.gallery-thumbnail-label {
    background-color: #fff;
    color: #000;
    display: block;
    margin: 10px 5px;
}
