.avtalerPanel{
    padding-top:50px;
    border:none;    
}

.avtalerList .glyphicon{
    margin-top:0px;
}
#filterRow{
    width:500px;
}
#filterRow  label{
    font-weight: bold;
    font-size:16px;
    margin-right: 10px;
}
#filterRow   select{
    width:170px;
    border:none;
    box-shadow: none;
}

#checkboxFilter label{
     margin-top:5px;
    font-weight: normal;
    font-size:16px;
}

/*.table>tbody>tr>td, .table>tbody>tr>th, 
.table>tfoot>tr>td, .table>tfoot>tr>th, 
.table>thead>tr>td, .table>thead>tr>th{ 
     border: none; 
 }*/
.avtalerList>thead>tr { 
  border:none;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  text-align: left;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 0px;   
  border: solid 1px #d8d8d8;

}
.avtalerList>thead>tr>td>div{
    padding-left:40px;    
}
 .avtalerList>tbody>tr:nth-child(even)>td{
	background-color: #f1f1f1;
}
.avtalerList>tbody>tr>td{
    padding:0px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.avtalerList #Dropdownlist{
    width:200px;
}

.table tbdoy> tr>td.avtalerDropdownlist{
    border-right: 1px solid lightgray;
}
/*.table>tbody>tr:first-child>td {
     font-size: 16px;
  font-weight: bold;
  font-style: normal;
 font-stretch: normal;
  line-height: 1.63;
  text-align: left;
  color: #000000;
   padding-left: 10px;
   padding-top: 20px;
   padding-bottom: 20px;
   border: solid 1px #d8d8d8;

}*/