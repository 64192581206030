#ressursbruk-th-tjeneste {
    min-width: 300px;
}

#ressursbruk-th-dato {
    min-width: 155px;
}

#ressursbruk-th-time {
    width: 150px;
}
#ressursbruk-th-skift {
    min-width: 125px;
}

#ressursbruk-th-mengde {
    min-width: 140px;
}

#ressursbruk-th-enhet {
    vertical-align: bottom;
    padding-bottom: 30px!important;
    white-space: nowrap;
    min-width: 60px;
}

#ressursbruk-th-kommentar {
    min-width: 190px;
}

#ressursbruk-th-handling {
    min-width: 90px;
    vertical-align: bottom;
}

#ressursbruk-th-handling button {
    height: 40px;
}

.ressursbruk-innmelding-form-kommentar-textarea:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
    border-radius: 5px;
}