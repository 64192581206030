ul.pagination li a {
  border:none; 
  margin-top:15px;
  margin-left:3px;
  float:left;
}

.paginationPrevButton{
  color:rgb(77, 77, 77);
  padding-left:2px;
  padding-right:20px;
  padding-top:1px;
  padding-bottom: 10px;
  margin-top:-0.5em;
  width:110px;
  height:40px;
}
.paginationNextButton{
  color:rgb(77, 77, 77);
  padding-left:20px;
  padding-right:2px;
  padding-top:1px;
  padding-bottom: 10px;
  margin-top:-0.5em;
  width:110px;
  height:40px;
}

@media (max-width: 1100px) {
  .paginationPrevButton,
  .paginationNextButton{
    color:rgb(77, 77, 77);
    padding: 1px;
    width:40px;
    height:40px;
  }
  
  .paginationPrevButton > .pagination-button-label,
  .paginationNextButton > .pagination-button-label{
    display: none;
  }
}

.pagination{
  font-size: 16px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

li.disabled {
  color:red;
  opacity: 0.6;
}

.glyphicon {
  font-size:15px;
  font-weight: normal;
  top:3px;
  margin-right:2px !important;
  margin-left:2px !important;
}
.btn-default{
  font-weight: normal;
}


.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  color:black;
  cursor: default;
  background-color:white;
  border-color: #337ab7;
  text-decoration: underline;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  background-color: white;
}

.pagination >li > a:focus ,.pagination >li > a.focus
{ 
  outline: none!important;
}