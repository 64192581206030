/*Edit User Form Css*/

.editUserForm .form-group:first-child{
    padding-top: 20px;
}

.editUserForm {
    padding-left: 70px;
}

.editUserForm label {
    color: #4d4d4d;
    font-size: 12px;
    font-weight: normal;
}

.editUserForm .formButtons {
    padding-top: 20px;
    padding-bottom: 18px;
}

.editUserForm .formButtons button{
    margin-right: 25px;
}

.editUserForm #roleSelect {
    width: 350px;
}

.editUserForm .Select {
    width: 350px;
}

#editUserSubmit {
    width: 132px;
}

.editUserForm > .form-group >p {
    font-size:16px;
}