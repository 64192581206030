hr {
  margin: 5px 0px 5px 0px;
  width: 100%;
}
.modal-header {
  background-color: rgb(241, 241, 241);
}
.searchDropdownList {
  max-width: 310px;
  height: 44px;
  border-radius: 7px;
}

#searchWrapper {
  background-color: #e9e9e9;
  height: 160px;
  width: 100%;
  padding-top: 10px;
  padding-left: 20%;
  margin-bottom: 20px;
}
.TopIconsDiv {
  margin-top: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.TopIconsDiv .btn {
  height: 50px;
  width: 150px;
  padding-left: 0px;
}
#listButton {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
  color: #777;
  font-size: 16px;
  font-weight: bold;
  outline: 0 none;
}
#MapButton {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
  color: #777;
  font-size: 16px;
  font-weight: bold;
  outline: 0 none;
}
#PrinterButton {
  border-radius: 6px;
  border: solid 1px #e9e9e9;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: bold;
  outline: 0 none;
  padding-top: 2px;
  padding-left: 1px;
  margin-left: 50px;
  margin-right: 5px;
  text-align: center;
  width: 140px;
  height: 40px;
  margin-bottom: 0px;
}
#tableFilter > tbody > tr > td > div > #FilterLagreBtn {
  width: 140px;
  height: 40px;
  border-radius: 6px;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
  outline: 0 none;
  text-align: center;
}

.bym-glyphicon-spin {
  -webkit-animation: bym-spin 1000ms infinite linear;
  animation: bym-spin 1000ms infinite linear;
}

@keyframes bym-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

#printglyphicon,
.glyphicon-plus {
  top: 1px;
}
#skrivut {
  top: 0px;
  vertical-align: text-bottom;
}

.table {
  margin-top: 10px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: white;
}

.mapDisplay {
  padding-top: 10px;
  width: 100%;
}
.mapSearchLeftDiv {
  background-color: #f1f1f1;
  padding: 10px;
  left: 0;
  margin-left: 10px;
  position: absolute;
  z-index: 1;
  width: 400px;
  height: 1000px;
}

#searchbtn {
  margin-top: 5px;
  margin-bottom: 20px;
}

.mapFilterCheckBox {
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 80px;
}
.clearfix {
  margin-top: 10px;
}
.photoGallery {
  margin-top: 15px;
}
.mapDisplayRightDiv,
.mapDisplayRightDiv img {
  top: 0;
  left: 0;
  position: relative;
  width: 101%;
  height: 999px;
  z-index: -1;
}

img.basisKartBase64,
img.kartLagBase64 {
  top: 0;
  left: 0;
  position: absolute;
}

.divider-vertical {
  border-color: #4d4c4d;
  border-left-color: #111111;
  height: 40px;
  /*margin: 0 9px;
	border-right: 1px solid #ffffff;
	border-left: 1px solid #f2f2f2;
    position: relative;*/
  margin: 10px;
}
@media (max-width: 1007px) {
  .filterDiv {
    position: absolute;
  }
  .filterDiv > #tableFilter {
    max-width: 100vw;
    white-space: nowrap;
    overflow: scroll;
  }
}

.filterDiv {
  background-color: rgb(255, 255, 255);
  border-bottom: solid 2px rgb(216, 216, 216);
  position: fixed;
  width: 100vw;

  z-index: 11;
}
.filterDiv-placeholder {
  height: 55px;
  background-color: rgb(255, 255, 255);
}
#tableFilter {
  max-height: 55px;
  margin: 0px;
  padding: 0px;
  border-collapse: collapse;
  max-width: 100vw;
}

#tableFilter > tbody > tr > td {
  border-left: 2px;
  border-right: 2px;
  border-top: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-radius: 0px;
  border-color: rgb(216, 216, 216);
}
#tableFilter .no-border-right {
  border-right: 0px;
}
#tableFilter .no-border-left {
  border-left: 0px;
}
.datepicker-cell {
  width: 140px;
  padding: 0px 8px;
}

#checkAll {
  margin-left: 56px;
}
#filterDropdown {
  height: 100%;
}
#filterDropdown:focus {
  box-shadow: none;
}

.selectedFiltersContainer {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 5px;
  flex-wrap: nowrap;
}

.saved-filters .filterRemoveButtons {
  margin-left: 5px;
}

.selectedFiltersContainer .filterRemoveButtons {
  display: flex;
  align-items: center;
}

#ChoosenfilterDiv {
  height: 53px; /*63px;*/
  padding-left: 56px;
  padding-top: 5px; /*10px;*/
  padding-bottom: 5px;
  border-bottom: solid 2px rgb(216, 216, 216);
  background-color: rgb(255, 255, 255);
  position: fixed;
  width: 100%;
  z-index: 10;
  overflow-x: auto;
  white-space: nowrap;
}

#ChoosenfilterDiv-placeholder {
  background-color: rgb(255, 255, 255);
  height: 53px;
}
.filterRemoveButtons {
  border: none;
  background-color: rgb(241, 241, 241);
  text-align: center;
  line-height: 30px;
  min-width: 1;
}
.filterRemoveButtons div {
  padding-left: 10px;
  min-width: 100%;
  padding-right: 10px;
}
span#filterRemoveButtons {
  padding: 5px;
}

#glyphicon-remove-sign {
  margin-top: 2px;
  margin-right: -2px;
  color: rgb(188, 188, 188);
}

#fritextField {
  max-width: 50%;
  width: 80em;
}
#seachInMessages > .form-group {
  width: 80em;
  max-width: 80em;
}

p {
  font-size: 12px;
}
.fontstyle {
  font-size: 19px;
}

#filtervalg {
  line-height: 35px;
}
.filter-count-circle {
  background: rgb(217, 234, 245);
  border-radius: 50%;
  color: rgb(74 74 74);
  padding: 6px 10px 6px 10px;
  text-align: center;
  font-size: 12px;
  margin-left: 7px;
}
.kommentarList strong {
  font-size: 14px;
  line-height: 23px;
}
.kommentarList .description {
  font-size: 16px;
  line-height: 23px;
  white-space: pre-line;
}

.kommentarList .date {
  font-size: 12px;
  line-height: 23px;
}
.hr-bold {
  border-top: 1px solid rgb(204, 204, 204);
}
.historic-row-felts,
.historic-div-values {
  padding-top: 5px;
}
.historic-div-values .HistoricNewValue,
.historic-div-values .HistoricOldValue {
  white-space: pre-line;
  word-wrap: break-word;
}
.historic-row-felts .col-xs-4,
.historic-div-values .col-xs-4 {
  padding-left: 0px;
  font-size: 12px;
}
.historic-row-felts .col-xs-4 {
  font-weight: bold;
  font-size: 13px;
}
.historic-row-values {
  font-weight: normal;
}
#tilbakepanelheading .panel {
  border-bottom: rgb(241, 241, 241) solid 1px;
}
.meldingComments .kommentarList,
.meldingHistoric {
  padding: 0px 0px 0px 20px;
}

.meldingHistoric:nth-of-type(even) {
  background-color: rgb(241, 241, 241);
}

#hideRutingsammendrag {
  display: none;
}

@media (max-width: 1351px) {
  #PrinterButton {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    width: 40px;
    height: 40px;
    margin-bottom: 0px;
  }
  #PrinterButton > .print-btn-text {
    display: none;
  }
}

.pagination-container-fixed {
  background-color: #fff;
  border-top: #4d4c4d;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 59px;
  width: 50%;
}

@media (max-width: 767px) {
  .pagination-container-fixed {
    width: 100%;
  }
}

#tableFilter-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 56px;
}
#tableFilter-flex > .tableFilter-flex-group {
  border-left: solid 2px rgb(216, 216, 216);
  height: 55px;
  padding: 0 5px;
}

#tableFilter-flex > .tableFilter-flex-no-border {
  border-left: none;
}


#tableFilter-flex > .tableFilter-flex-group > button,
#tableFilter-flex > .tableFilter-flex-group > div > #FilterLagreBtn,
#tableFilter-flex > .tableFilter-flex-group-date > span .filter-datepicker,
#tableFilter-flex > .tableFilter-flex-group > div > #NyMeldingButton {
  margin: 9px 0;
}

#tableFilter-flex > .tableFilter-flex-group > .tableFilter-flex-group-grow {
  flex-grow: 2;
}
#tableFilter-flex > .tableFilter-flex-group > .tableFilter-flex-group-date {
  min-width: 120px;
  overflow: hidden;
}
#tableFilter-flex > .tableFilter-flex-group > .multi-filter-dropdown > button {
  text-overflow: ellipsis;
}
#tableFilter-flex > .tableFilter-flex-group > .multi-filter-dropdown > button > .multi-filter-dropdown-shortname {
  display: none;
}

@media (max-width: 1100px) {
  #tableFilter-flex {
    margin-left: 0;
  }
}

@media (max-width: 885px) {
  .tableFilter-flex-hide-on-mobile {
    display: none;
  }
  #tableFilter-flex > .tableFilter-flex-group > .multi-filter-dropdown > button {
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
  }
  #tableFilter-flex > .tableFilter-flex-group > .multi-filter-dropdown > button > .multi-filter-dropdown-longname {
    display: none;
  }
  #tableFilter-flex > .tableFilter-flex-group > .multi-filter-dropdown > button > .multi-filter-dropdown-shortname {
    display: inline;
  }
  #tableFilter-flex > .tableFilter-flex-group > .multi-filter-dropdown > button > .caret {
    display: none;
  }
  #tableFilter-flex > .tableFilter-flex-group-date {
    max-width: 34px;
  }
}