/* UserList */

.userList table > tbody > tr > td:nth-child(1) {
    width: 560px;
}

.userList table > tbody > tr > td:nth-child(2) {
    vertical-align: middle;
}

.userList .sk-spinner {
    margin:auto;
    width: 10%;
}

.userList .error {
    color: #a94442;
}