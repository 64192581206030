/* Entrepreneur Css */

.entrepreneur_center_div  .panel{
  min-height: 700px;
	margin-top: 7px;
	border: none;
}

.gap-10 {
	width:100%;
	height:10px;
}
.gap-20 {
	width:100%;
	height:20px;
}
.gap-30 {
	width:100%;
	height:30px;
}
.gap-50 {
	width:100%;
	height:50px;
}
.gap-100 {
	width:100%;
	height:100px;
}
      
.text-nowrap {
	white-space:nowrap;
}

.text-21px {
	font-size:21px;
}

.margin-top-10px {
  margin-top:10px;   /*note: denne må være ca det samme som font-sizen til .text-21px klassen  - 11px  =  10px */
}

.text-21px {
	font-size:21px;
}

.text-16px {
	font-size:16px;
}

.text-14px {
	font-size:14px;
}

.text-13px {
	font-size:13px;
}

.text-12px {
	font-size:12px;
}


.text-default-px {
	font-size: 100%;
}

.red {
	color: red;
}

.green {
	color: green;
}

.table-fixed {
	table-layout: fixed;
}

.div-center {
  	text-align: center;
	vertical-align: middle;
}

#nyttskjema-button {
    line-height: 12px;
    margin-top: 4px;
    margin-right: 0px;
	padding: 0;
	vertical-align: top;
 }

.indent {
    margin-left: 22px;
}

#entrepreneur-tabs .nav-tabs{
    border: none;
}

#entrepreneur-tabs .nav-tabs li > a{
    border: none;
}

#entrepreneur-tabs .nav-tabs a{
    font-size: 21px;
    text-transform: uppercase;
    font-weight: bold;
}

#entrepreneur-tabs .nav-tabs li.active{
    border-bottom: 2px solid #ffa500;
    padding: 0;
}

#orgnumber{
	width:300px;
}
#fixlabelsize{
	width:180px;
	margin-top:10px;
}

.ressursbruk-row-staticFieldPadding {
	padding-left: 12px;
}

#editablewidth >.editable-field >.editable-field-textarea {
		height:30px;
}
 #editablewidth >span> .input-group-addon{
	height:30px;
	padding:0px;
	border-right:5px;
}
 #editablewidth >span>.input-group-addon> .glyphicon{
	 top:0px;
 }
/*#editablewidth >.editable-field-buttons > .btn-default{
	font-size: 1px;
	padding:1px;
}
#editablewidth >.glyphicon{
	margin-top:1px;
	margin-bottom:2px;	
}*/

#editablewidth >.editable-field >span >button{
	font-size:1px;
	padding:0px;
}
#editablewidth >.editable-field >span >button>.glyphicon{
	top:0px;
}