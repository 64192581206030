/* Collapsible User */

#chevronDiv {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    height: 42px;
}

#chevronDiv span {
    padding-right: 25px;
}