.folgere-icon {
  /* width: 120px;  */
  /* height: 20px; */
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid;
  font-size: 13px;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  line-height: normal;
  border-color: #4D4D4D;
  background-color: #E5E5E5;
  padding: 1px 6px;
  max-width: fit-content;
}

.folgere-icon .glyphicon {
  font-size: inherit;
  margin: unset;
  padding: unset;
  top: unset;
  vertical-align: middle;
  margin-right: 5px !important;
}