#meldingListRowDiv{
    padding:5px 5px 5px 5px;
    border-bottom: 2px lightgray solid;
    cursor: pointer;
    clear: both;
    display: block;
    line-height: 26px;
}
#meldingListRowDiv:last-child {
    margin-bottom: 65px;
}

#meldingListRowDiv:nth-of-type(odd) {
    background-color: rgba(241, 241, 241, 0.2);
}

#meldingListRowDiv.active {
    background-color: rgba(1, 115, 182, 0.16);
}



.meldingListRowRow {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#meldingListRowCheckbox{
    float: left;
    margin-left: 21px;
}

.meldingListRowLeft {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 45%;
}

.meldingListRowRight {
    align-items: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 55%; 

}

.meldingListRowPills {
    display: flex;
    height: fit-content;    
}

.meldingListRowPills > div {
    margin-right: 5px;
}

.meldingListRowDate {
    padding-right: 10px;
    font-size: 14px;
    flex: auto;
    display: flex;
    justify-content: flex-end;
}
.meldingListRowDueDate {
    padding-right: 10px;
    font-size: 14px;
    flex: auto;
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
}
.meldingListRowDueDate .overdue {
    color: red;
}
.meldingListRowDueDate .near-due-date {
    color: #F6A623;
}

.meldingSort{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.meldingSort-bySelect {
    width: 165px;
    margin: 5px;
}
.meldingSort-label {
    height: 34px;
    padding-top: 12px;
}
