.status-icon {
    width: 120px; 
    /* height: 20px; */
    border-radius: 10px;
    border: 1px solid;
    font-size: 13px;
    padding: 1px;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
}
.status-icon-ikke-tildelt {
    border-color: #00548C;
    background-color: #F1F7FB;
}
.status-icon-ikke-behandlet {
    border-color: #4D4D4D;
    background-color: #E5E5E5;
}
.status-icon-avvist {
    border-color: #C3190C;
    background-color: #F6D3D0;
}
.status-icon-under-behandling {
    border-color: #FFAB0D;
    background-color: #FFEECE;
}
.status-icon-ferdig-behandlet {
    border-color: #198656;
    background-color: #D3F0E3;
}
.status-icon-ukjent {
    border-color: #4D4D4D;
    background-color: #E5E5E5;
}
