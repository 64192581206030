.prisskjema-header{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 21px;
}

.processcode-table > tbody > tr:nth-child(1) .form-group {
    margin-bottom: 0;
}

.processcode-table thead > tr > th {
    width: 300px;
}

.processcode-table thead > tr > th:nth-child(2) {
    width: 469px;
}

.processcode-table thead > tr > th:nth-child(3) {
    width: 71px;
}

.processcode-table thead > tr > th:nth-child(4) {
    width: 30px;
}

.processcode-table thead > tr > th:nth-child(5) {
    width: 200px;
}

.processcode-table .danger {
    padding-left: 47px;
}

.processcode-table > tbody > tr:hover{
    background-color: rgb(192, 192, 192) !important;
}


#controlled-tab-example-pane-2, #controlled-tab-example-tab-2 {
    background-color: aliceblue;
}