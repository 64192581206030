.meldingsdetaljer-section {
    font-size: 12px; 
    margin: 0 0 10px;
}

.meldingsdetaljer-section .overdue {
    color: red;
}
.meldingsdetaljer-section .near-due-date {
    color: #F6A623;
}

.meldingsdetaljer-section-label {
    min-width: 80px;
    padding-left: 15px;
    margin-right: 15px;
    display: inline-block;
}
.meldingsdetaljer-section > .status-icon {
    display: inline-block;
}

.tilbakemeldingerContainer{
    background-color:rgb(241, 241, 241);
}

.topBannerWrapper{
    background-color: white;
    padding-top:50px;
    height: 250px;
    padding-bottom: 50px;
}
.topBannerSendingMelding{
    background-color:#f2f8fc;
    padding:10px;
}
.tilbakeMeldingBackButton{
    margin-bottom: 0px;
    background-color:white;
    border:none
    
}
.tilbakeMeldingerMap{
    background-color:white;

}
.meldingDescription,.sendMeldingDropdownBoxes, .zoneAndPlace,.SendMeldingMapp{
    margin-top:15px;
    padding:10px;    
}
.meldingDescription{
     margin-top:15px;
     padding-top:5px;
     padding-bottom: 0px;
     margin-bottom: 0px;
     margin-left:10px;
}
.meldingDescription > strong,
.meldingDescription > span {
    white-space: pre-wrap;
}

.mapContainer img{
    margin-top:10px;
}

#MeldingCommentsTab{
    margin-top:50px;
    margin-bottom: 100px;
}

#commentsTextArea{
    width: 500px;
    height: 150px;
}
#submainContainer{
    background-color:  rgba(155, 154, 155, 0.2);
    padding: 75px 15px 50px ;
}
#topBannerWrapper{
    padding-top:50px;
    padding-bottom:20px;
    height:200px;
}

#tilbakeMeldingBackButton{
    margin-top:34px;
    margin-right:15px;
    border-top-left-radius:7px;
    border-top-right-radius:7px;
    background-color: white;
    
} 
#tilbakepanelheading > .panel {
    border:none;
}

#tilbakepanelheading > .panel > .panel-heading  {
     background-color:white;
}

#tilbakepanelheading > .panel > .panel-heading > .panel-title > a { 
    cursor: pointer;
}

#tilbakepanelmelding > .panel {
     border:none;   
}

#tilbakepanelmelding > .panel > .panel-heading  {
     background-color:white;
}

#meldingerButton{
    margin-left:15px;
}
#sendTiRutingButton{
    margin-top:10px;
    margin-right:0;
    margin-bottom: 20px;
    padding:7px 40px 7px 40px;
}
#printOutButton{
    margin-top:10px;
    margin-left:20px;
    margin-bottom: 20px;
    padding:0px 25px 7px 10px;
    font-weight: bold;
}
#meldingDetailsGallery > div > a > img{
   height: 1000px;
}

#sendmeldingBeskrivelse{
    font-size:16px;
    font-style: normal;
}
.form-control{
    font-size:16px;
}

.sendMeldingDropdownBoxes >#Dropdownlist :hover .form-control{
      border-color: #ccc;
    background-color: #fff;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.addingwhitespace{
    background-color: white;
}
.sendmeldingLabelId{
    width:300px;
}

.message-description-entrepreneur .form-group > div.control-label {
    margin-top: 10px;
}