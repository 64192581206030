#paddedButton{
 width:150px;
 font-weight:bold;
}

.btn-success, .btn-success.active:focus, .btn-success:active:focus, .btn-success:focus, .btn-success:hover {
    background-color: #daf6ed;
    border: 1px solid #b7ecdc;
    color:#4d4c4d;
    font-size: 16px;
    font-weight: 700;
    outline: 0 none;
}