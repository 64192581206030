
#personopplysning-warning-alert {
    max-width: 400px;
    margin-top: 36px;
    border-radius: 7px;
    border: solid 1px #ffa30f;
    background-color: #ffe1b0;
    text-align: left;
    padding: 24px 12px;

    font-size: 16px;
    color: #000000; 
}

.newMeldingModal > .modal-dialog{
    width:95%;
    border-radius: 10px;
}

.newMeldingModal >.modal-dialog .modal-header{
    border-radius: 10px;
    background-color: white;
    border:none
}
.newMeldingModal >.modal-dialog  .modal-title{
    font-size: 36px
}
.newMeldingModal > .modal-dialog .modal-content{
    padding:20px;    
  
}
.newMeldingForm input,.newMeldingForm select{
    width:480px;
    height: 40px;
}
.newMeldingForm textarea {
    width:480px;
    height: 186px;
}
input#telefon{
    width:165px;
    height: 40px;
}
#tableFilter .no-border-left .bym-add-btn {
    float:none;
    margin:0px auto;
    margin-right: 10px;    
}
#NyMeldingButton{
    border-radius: 6px;
    border: solid 1px #e9e9e9;
    background-color: #f3f3f3;
    font-size: 16px;
    font-weight: bold;
    outline: 0 none;
    padding-top: 2px;
    padding-left: 1px;
    margin-right: 5px;
    text-align: center;
    width: 140px;
    height: 40px;
    margin-bottom: 0px;
}

@media(max-width:1351px){
    #NyMeldingButton{
        padding-left: 5px;
        padding-right: 5px;
        margin-left:5px;
        margin-right:5px; 
        text-align:center;
        width:40px;
        height:40px;
        margin-bottom: 0px;
      }
    #NyMeldingButton > .plus-btn-text {  
        display: none;
    }    
}
#buttonLink{
    padding-left:0px
}
.NewMeldingImages{
    margin-top:50px;
    margin-bottom: 50px;
}

.newMeldingModal input#addressSearch{
    width:480px;
    height: 40px;
}

.newMeldingModal .form-group label, .address-input-group label{
    font-size: 16px;
    font-weight: bold
}
#plusglyphicon{
    padding-right:4px;
    top:0px;
    font-size: 18px;
    vertical-align: text-bottom;
}

/* mobile */
@media (max-width: 767px) {

.newMeldingForm input,.newMeldingForm select{
    width:280px;
    height: 40px;
}
.newMeldingForm textarea {
    width:280px;
    height: 186px;
}
}